.projects--list {
  margin-top: 50px;

  ul {
    list-style-type: none;
  }

  a {
    &,
    &:visited {
      color: #fff;
      text-decoration: none;
    }

    &:hover,
    &focus {
      text-decoration: underline;
    }
  }
}
