.loading {
  background: linear-gradient(-44deg, #4E88FF 0%, #15CFC2 100%);
  height: 100%;
  position: fixed;
  width: 100%;

  &--spinner {
    margin: 0 auto;
    position: relative;

    &--logo {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 40px;
    }

    &--spin {
      animation: rotateSpin 1s infinite linear;
      border-bottom: 1em solid rgba(255, 255, 255, 0.2);
      border-left: 1em solid #fff;
      border-right: 1em solid rgba(255, 255, 255, 0.2);
      border-top: 1em solid rgba(255, 255, 255, 0.2);
      font-size: 15px;
      margin: 60px auto;
      position: relative;
      text-indent: -9999em;
      transform: translateZ(0);

      &,
      &::after {
        border-radius: 50%;
        height: 10em;
        width: 10em;
      }
    }

    @keyframes rotateSpin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
