.presentation__header {
  align-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5em;
  font-weight: 500;
  justify-content: space-between;
  transition: color .3s;
  position: relative;
  z-index: 1;

  .logo {
    margin-right: 15px;
  }

  &__logo {
    opacity: .5;
  }
}

.is-read-mode {
  .presentation__header {
    color: #000;
  }
}
