.presentation--pagination {
  &--fraction {
    flex-shrink: 0;
  }

  &--bullets {
    padding: 0;
    width: 100%;
    z-index: 10;

    .swiper-pagination-bullet {
      background: transparent;
      border: 1px solid #fff;
      height: 11px;
      opacity: 1;
      transition: background .3s;
      margin-right: 8px;
      width: 11px;

      &-active {
        background: #fff;
      }
      @media (min-width: 768px) {
        &:hover::before {
          content: attr(data-heading);
          width: max-content;
          position: fixed;
          bottom: 50px;
        }
      }
    }
  }
}
