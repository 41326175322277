h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
}

h1 {
  margin-bottom: 40px;
}

p {
  margin: 0;
  margin-bottom: 20px;
}

h1 {
  font-size: 3.5em;
  line-height: 1.1;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 1.65em;
}

.font-small {
  font-size: 0.875em;
}

.font-large {
  font-size: 1.25em;
}

.font-light {
  font-weight: 300;
}

@media (max-width:768px) {
  h1 {
    font-size: 3em;
    line-height: 1.1;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.1em;
  }

  .font-small {
    font-size: 0.75em;
  }

  .font-large {
    font-size: 1em;
  }
}
