.presentation {
  color: #fff;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  li,
  p,
  span {
    transition: color .3s;
  }

  .presentation__layer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: opacity .3s;
    pointer-events: none;
    opacity: 0;

    &--blue {
      background: linear-gradient(-44deg, #4e88ff 0%, #15cfc2 100%);
    }

    &--red {
      background: linear-gradient(-48deg, #ce3460 0%, #d55c03 100%);
    }

    &--green {
      background: linear-gradient(-44deg, #1da490 0%, #b0c107 100%);
    }

    &--white {
      background-color: #fff;
      z-index: 1;
    }
  }

  &.is-read-mode .presentation__layer--white,
  &.color-blue .presentation__layer--blue,
  &.color-red .presentation__layer--red,
  &.color-green .presentation__layer--green {
    opacity: 1;
  }

  &.is-read-mode .presentation--slide>.container {
    max-height: max-content;
  }

  &.is-read-mode h1 {
    font-size: 4em;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;
    opacity: .8;
    position: fixed;
    transition: opacity .3s;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .swiper-button-prev {
      left: 5px;
    }

    .swiper-button-next {
      right: 5px;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  &.is-read-mode {
    color: #333;
  }

  .presentation__header__wrapper+.swiper-slide {
    padding-top: 10% !important;

    @media (max-width: 768px) {
      padding-top: 25% !important;
    }
  }

  &.is-read-mode .swiper-slide {
    height: max-content;
    min-height: 0 !important;
    padding-top: 100px;
  }
}
