.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 1200px;
}

.flex-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
}

.flex-left-center,
.flex-center-center {
  align-content: center;
  align-items: center;
}

.flex-center-center {
  justify-content: center;
}

.flex-left-center {
  justify-content: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-3 {
  width: 25%;
  flex-basis: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-4 {
  width: 33.333333333333336%;
  flex-basis: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-5 {
  width: 41.66666666666667%;
  flex-basis: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-6 {
  width: 50%;
  flex-basis: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-7 {
  width: 58.333333333333336%;
  flex-basis: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-8 {
  width: 66.66666666666667%;
  flex-basis: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-9 {
  width: 75%;
  flex-basis: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.flex-sm-12 {
  @media (max-width: 1024px) {
    width: 100%;
    flex-basis: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.display-flex,
.display-flex-column {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.display-flex-column {
  flex-direction: column;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

ul,
ol {
  padding-left: 25px;

  li:not(last-child) {
    margin-bottom: 10px;
  }
}

.padding-top-md {
  padding-top: 30px;
}

.margin-right-sm {
  margin-right: 20px;
}

.margin-yaxis-md {
  margin-bottom: 30px;
  margin-top: 30px;
}

.spacing-xl {
  margin-bottom: 80px;
}

.spacing-lg {
  margin-bottom: 50px;
}

.spacing-md {
  margin-bottom: 30px;
}

.spacing-sm {
  margin-bottom: 20px;
}

.spacing-xs {
  margin-bottom: 10px;
}
