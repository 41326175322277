@import '~normalize.css';

html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: inherit;
}

html,
body {
  min-height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(-44deg, #4E88FF 0%, #15CFC2 100%);
  color: #fff;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
}

img {
  height: auto;
  max-width: 100%;
}
