.presentation--slide {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.25em;
  justify-content: center;
  text-align: left;
  z-index: 2;

  .table-of-contents {
    text-align: left;

    button {
      border: none;
      background: none;
      color: inherit;
      cursor: pointer;
      outline: none;
    }
  }

  /* targeting slide content to account for too much content */
  .container {
    padding-left: 25px;
    padding-right: 25px;

    @media (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media (max-width: 768px) {
    font-size: 1em;

    li {
      text-align: left;
    }
  }

  .presentation--column {
    @media (min-width: 1023px) {
      text-align: left;
    }

    img {
      margin: 0 auto;
    }
  }

  .presentation--image {
    margin: 0 auto;
    text-align: center;

    figcaption {
      margin-top: 5px;
    }
  }
}

.swiper-container {
  margin: 0 !important;

  @media (max-width: 768px) {
    height: min-content !important;
  }
}
