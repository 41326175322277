.presentation__buttons {
  width: 40px;
  height: 40px;
  position: relative;

  .presentation__button {
    color: currentColor;
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity .3s;
    pointer-events: none;
    opacity: 0;
  }

  span {
    display: none;
    font-size: .8em;
    position: absolute;
    width: max-content;
    right: 35px;
    top: 7px;
  }

  @media (min-width: 769px) {
    &:hover span {
      display: block;
    }
  }

  .svg-icon {
    fill: currentColor;
  }
}

.is-presentation-mode .presentation__button--read,
.is-read-mode .presentation__button--presentation {
  pointer-events: auto;
  opacity: .6;

  &:hover {
    opacity: 1;
  }
}

.is-presentation-mode .presentation__button--read {
  color: #fff;
}
